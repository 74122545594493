import React from 'react';
import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import NotificationsProvider from "@core/components/NotificationsProvider";
import Layout from "@core/components/Layout";
import ProtectedRoute from "@core/components/ProtectedRoute";
import Account from './Account';
import Login from './Login';
import Logout from "./Logout";
import NotFoundPage from "./NotFound";
import Dashboard from "./Dashboard";
import Certificate from "./Certificate";
import Test from "./Test";
import Orders from "./Orders";
import LineItem from './LineItem';
import theme from './themeConfig';

const App = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <NotificationsProvider>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/login' component={Login}/>
                    <ProtectedRoute>
                        <Layout>
                            <Switch>
                                <Route exact path="/logout" component={Logout}/>

                                <Route exact path={['/', '/index']} component={Dashboard}/>
                                <Route path='/index/certificate/:id' component={Certificate}/>
                                <Route path='/index/test/:id' component={Test}/>

                                <Route exact path='/orders' component={Orders}/>
                                <Route path='/orders/:id/:lineItem' component={LineItem}/>

                                <Route exact path='/account' component={Account}/>

                                <Route exact path='/not-found' component={NotFoundPage}/>
                                <Redirect to="/not-found"/>
                            </Switch>
                        </Layout>
                    </ProtectedRoute>
                </Switch>
            </BrowserRouter>
        </NotificationsProvider>
    </ThemeProvider>
);

export default App;
