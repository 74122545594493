const STATUSES = {
  ATTEST: "ATTEST",
  INSPECTING: "INSPECTING",
  ASSIGNED: "ASSIGNED",
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  EDITABLE: "EDITABLE",
  FILLED: "FILLED",
  EMPTY: "EMPTY",
  NEW: "NEW",
};

const   TYPES = {
  CHEMICAL: "chemical_composition",
  IMPACT: "impact_test",
  TENSILE: "tensile_test",
  HARDNESS: "hardness_test",
  HYDROSTATIC: "hydrostatic_test",
  CORROSION: "corrosion_test",
  RADIOGRAPHIC: "radio_graphic_report",
  LIQUID: "liquid_penetrant_test",
  BEND: "bend_test",
  NOTES: "notes_test",
  TREATMENT: "treatment_test",
  OTHER: "other_test",
  ULTRASONIC: "ultrasonic_test",
  MAGNETIC: "magnetic_particle",
  AUSTENITE: "austenite_spacing",
  FERRITE: "ferrite_content",
  MICROGRAPHIC: "micrographic_test",
  HEATTREATMENT: "heat_treatment",
  SPLIT: "split_certificate",
  SUPPLEMENT: "supplement_test",
  STATEMENT: "statement_test",
  SUPPLIER: "supplier_certificates"
};

const TEST_NAME_BY_TYPE = {
  [TYPES.CHEMICAL]: "Chemical Composition",
  [TYPES.IMPACT]: "Impact Test",
  [TYPES.TENSILE]: "Tensile Test",
  [TYPES.HARDNESS]: "Hardness Test",
  [TYPES.HYDROSTATIC]: "Hydrostatic Test",
  [TYPES.CORROSION]: "Corrosion Test",
  [TYPES.RADIOGRAPHIC]: "Radiographic Report",
  [TYPES.LIQUID]: "Liquid Penetrant Test",
  [TYPES.BEND]: "Bend Test",
  [TYPES.NOTES]: "Notes",
  [TYPES.TREATMENT]: "Treatment",
  [TYPES.OTHER]: "Other tests and Guarantees",
  [TYPES.ULTRASONIC]: "Ultrasonic Test",
  [TYPES.MAGNETIC]: "Magnetic Particle Examination",
  [TYPES.AUSTENITE]: "Austenite spacing",
  [TYPES.FERRITE]: "Ferrite Content Measurement",
  [TYPES.MICROGRAPHIC]: "Micrographic Test",
  [TYPES.HEATTREATMENT]: "Heat Treatment",
  [TYPES.SPLIT]: "Split of certificate",
  [TYPES.SUPPLEMENT]: "Supplier certificates",
  [TYPES.STATEMENT]: "Certificate statements",
  [TYPES.SUPPLIER]: "Supplier certificates",
};

const TEST_ORDER = [
  TYPES.CHEMICAL,
  TYPES.TENSILE,
  TYPES.IMPACT,
  TYPES.BEND,
  TYPES.CORROSION,
  TYPES.HARDNESS,
  TYPES.AUSTENITE,
  TYPES.FERRITE,
  TYPES.HYDROSTATIC,
  TYPES.LIQUID,
  TYPES.MAGNETIC,
  TYPES.MICROGRAPHIC,
  TYPES.RADIOGRAPHIC,
  TYPES.ULTRASONIC,
  TYPES.HEATTREATMENT,
  TYPES.TREATMENT,
  TYPES.OTHER,
  TYPES.STATEMENT,
  TYPES.SUPPLEMENT,
  TYPES.NOTES,
];

export {STATUSES, TYPES, TEST_NAME_BY_TYPE, TEST_ORDER};