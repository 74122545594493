import React from "react";
import {isEmpty} from "ramda";
import {Table, TableHead, TableCell, TableBody, TableRow, Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {STATUSES, TYPES, TEST_NAME_BY_TYPE} from '@core/constants/test';
import styles from "./styles";

const useStyles = makeStyles(styles);

const TEST_RESULTS = {
    NEW_TEST: "NEW_TEST",
    AWAITING_RESULT: "AWAITING_RESULT",
    FILLED: "FILLED",
    SATISFACTORY: "SATISFACTORY",
    UNSATISFACTORY: "UNSATISFACTORY",
    PRESENT: "PRESENT",
    COMPLETE: "COMPLETE"
};

const TEST_DESCRIPTION_BY_RESULT = {
    [TEST_RESULTS.NEW_TEST]: {
        text: "New test",
        color: "#d4a237",
    },
    [TEST_RESULTS.AWAITING_RESULT]: {
        text: "Awaiting result",
        color: "#d4a237",
    },
    [TEST_RESULTS.FILLED]: {
        text: "Filled",
        color: "#4caf50",
    },
    [TEST_RESULTS.SATISFACTORY]: {
        text: "Satisfactory",
        color: "#4caf50",
    },
    [TEST_RESULTS.UNSATISFACTORY]: {
        text: "Unsatisfactory",
        color: "#D02D52",
    },
    [TEST_RESULTS.PRESENT]: {
        text: "Present",
        color: "#4caf50",
    },
    [TEST_RESULTS.COMPLETE]: {
        text: "Complete",
        color: "#4caf50",
    }
};

const getTestResult = (test) => {
    if(test.type === TYPES.SUPPLIER) return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.COMPLETE];
    if (test.status === STATUSES.NEW) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.NEW_TEST];
    }
    if ([STATUSES.EMPTY, STATUSES.ASSIGNED, STATUSES.INSPECTING].includes(test.status)) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.AWAITING_RESULT];
    }
    if ([TYPES.NOTES, TYPES.OTHER, TYPES.SUPPLEMENT, TYPES.STATEMENT].includes(test.type)) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.FILLED];
    }
    if ([TYPES.RADIOGRAPHIC, TYPES.AUSTENITE, TYPES.HEATTREATMENT].includes(test.type)) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.SATISFACTORY];
    }
    if (test.type === TYPES.CHEMICAL &&
        ["Weld", "Haz"].includes(test.properties.zone) &&
        test.properties.elements.length !== 0) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.PRESENT];
    }
    if (test.properties && (test.properties.result === "Acceptable" ||
        test.properties.result === "Not defective" ||
        test.properties.result === "No recordable indications")) {
        return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.SATISFACTORY];
    }
    return TEST_DESCRIPTION_BY_RESULT[TEST_RESULTS.UNSATISFACTORY];
};

const Summary = ({executeScroll, tests}) => {
    const classes = useStyles();

    if (isEmpty(tests)) {
        return (
            <span>No Data</span>
        );
    }
    return (
        <Table size="small" className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>Tests and Analyses</TableCell>
                    <TableCell>Witness</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Lab</TableCell>
                    <TableCell>Result</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tests.map((test) => {
                    const result = getTestResult(test);
                    return (
                        <TableRow key={test._id}>
                            <TableCell scope="row">
                                <Link onClick={() => executeScroll(test._id)}>
                                    {TEST_NAME_BY_TYPE[test.type]}
                                </Link>
                            </TableCell>
                            <TableCell scope="row">
                                {isEmpty(test.witnesses) ?
                                    "—" :
                                    test.witnesses.map((witness, index) => {
                                        if(!witness.company) return null;
                                        return (
                                            <span key={`witness${test._id}${index}`}>
                                                {index && ", "}{witness.company.name}
                                            </span>
                                        );
                                    })
                                }
                            </TableCell>
                            <TableCell scope="row">
                                {test.company ? test.company.name : "-"}
                            </TableCell>
                            <TableCell scope="row">
                                {test.assignee ? test.assignee.name : "—"}
                            </TableCell>
                            <TableCell scope="row" style={{color: result.color}}>
                                {result.text}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default Summary;