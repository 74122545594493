import React from "react";
import {Button, Grid} from "@material-ui/core";
import {STATUSES} from "@core/constants/test";

const Split = (props) => {
    const {test} = props;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>
                        Split from
                        <Button size={"medium"} href={`/index/certificates/${test.properties.parentCertificate}`}>
                            {test.properties.parentCertificate}
                        </Button>
                    </h2>
                </Grid>
            </Grid>

            {[STATUSES.ATTEST, STATUSES.APPROVED].includes(test.status) &&
            <Grid container spacing={2} justify={"center"}>
                <Grid item xs={3}>
                    <img src="/images/security.png" alt="attested"/>
                </Grid>
            </Grid>
            }

        </>);
}

export default Split;
